<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    const {oauth_success: oauthSuccess} = this.$route.query;
    oauthSuccess ? localStorage.setItem('oauthSuccess', oauthSuccess) : this.$router.push({name: 'Home'});
  },
};
</script>
